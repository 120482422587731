import { Flex, FloraTypography as Text } from '@grupoboticario/flora-react';

interface ResultNotFoundProps {
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
  button?: React.ReactNode;
}

export const EmptyState = ({ title, subtitle, icon, button }: ResultNotFoundProps) => {
  return (
    <Flex gap='$4' css={{ marginTop: '$12' }} direction='column' align='center' justify='center'>
      {icon}
      <Text fontSize='subtitle'>{title}</Text>
      <Text fontSize='bodyLargeStandard'>{subtitle}</Text>
      {button}
    </Flex>
  );
};
