import { BiometriaEnum } from '@/types';

export const biometriaOptions = [
  {
    label: 'Inconclusivo',
    value: BiometriaEnum.INCONCLUSIVO,
  },
  {
    label: 'Reprovado',
    value: BiometriaEnum.REPROVA,
  },
  {
    label: 'Aprovado',
    value: BiometriaEnum.APROVA,
  },
  {
    label: 'Sem Biometria',
    value: BiometriaEnum.SEM_BIOMETRIA,
  },
];
