// eslint-disable-next-line import/no-unresolved
import { GtmTracker } from '@vdi/event-tracker';
import { createAttendanceEvents } from './attendance';
import { useAssistantMailingStore } from '@/store/assistant';

const useEvents = () => {
  const { tableState } = useAssistantMailingStore((state) => state.tableMailing);

  return {
    ...createAttendanceEvents(GtmTracker, tableState),
  };
};

export { useEvents };
