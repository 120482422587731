import { BiometriaEnum } from '@/types';
import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import {
  CheckSingleIcon,
  CircleDashIcon,
  CrossIcon,
  InformationCircleIcon,
} from '@grupoboticario/flora-react-icons';

const biometricCollectionToString: Record<BiometriaEnum, string> = {
  [BiometriaEnum.APROVA]: 'Aprov.',
  [BiometriaEnum.INCONCLUSIVO]: 'Inconcl.',
  [BiometriaEnum.REPROVA]: 'Reprov.',
  [BiometriaEnum.SEM_BIOMETRIA]: 'Sem Bio',
};

const biometricCollectionIcon: Record<BiometriaEnum, JSX.Element> = {
  [BiometriaEnum.APROVA]: <CheckSingleIcon color='$success-alt' />,
  [BiometriaEnum.INCONCLUSIVO]: <InformationCircleIcon color='$nonInteractiveAltEmphasis' />,
  [BiometriaEnum.REPROVA]: <CrossIcon color='$statusErrorActionableActive' />,
  [BiometriaEnum.SEM_BIOMETRIA]: <CircleDashIcon color='$nonInteractiveAltAuxiliar' />,
};

interface BiometricCollectionProps {
  biometricValue: BiometriaEnum;
}

export const BiometricCollection = ({ biometricValue }: BiometricCollectionProps) => (
  <Flex justify='center' css={{ gap: '$1' }} align='center' data-testid='biometric-collection'>
    {biometricCollectionIcon[biometricValue]}
    <FloraTypography fontSize='bodySmallShort' data-testid={`biometria-${biometricValue}`}>
      {biometricCollectionToString[biometricValue] ?? '---'}
    </FloraTypography>
  </Flex>
);
