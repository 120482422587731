import { RiskAnalysis } from '@/types';
import { FloraTypography as Text, CSS } from '@grupoboticario/flora-react';

type UsedRiskAnalysis = Exclude<RiskAnalysis, RiskAnalysis.NOT_PROCESSED>;

interface RiskAnalysisProps {
  riskAnalysis: RiskAnalysis;
}

type ColorThemeProps = CSS['color'];

const handleRiskAnalysisColor: Record<UsedRiskAnalysis, ColorThemeProps> = {
  [RiskAnalysis.LOW_RISK]: '$success-alt',
  [RiskAnalysis.MEDIUM_RISK]: '$alert-standard',
  [RiskAnalysis.HIGH_RISK]: '$error-standard',
};

const riskAnalysisToString: Record<UsedRiskAnalysis, string> = {
  [RiskAnalysis.LOW_RISK]: 'Baixo',
  [RiskAnalysis.MEDIUM_RISK]: 'Médio',
  [RiskAnalysis.HIGH_RISK]: 'Alto',
};

export const RiskTag = ({ riskAnalysis }: RiskAnalysisProps): JSX.Element => (
  <Text
    fontSize='bodySmallShort'
    data-testid={`risk-tag-${riskAnalysis}`}
    css={{
      alignItems: 'center',
      display: 'flex',
      height: '$6',
      width: 'fit-content',
      color: '$nonInteractivePredominant',
      '&:before': {
        background: handleRiskAnalysisColor[riskAnalysis as UsedRiskAnalysis],
        borderRadius: '$large',
        content: ' ',
        height: '$6',
        marginRight: '$2',
        width: '$1',
      },
    }}
  >
    {riskAnalysisToString[riskAnalysis as UsedRiskAnalysis]}
  </Text>
);
