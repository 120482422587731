import { ControlQueryKey } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { getBaseMediaSourceOptions } from '../getBaseMediaSourceOptions';

export const useGetBaseMediaSourceOptions = () => {
  const fetchQuery = useQuery({
    queryKey: [ControlQueryKey.BASE_MEDIA_SOURCE_OPTIONS],
    queryFn: getBaseMediaSourceOptions,
    staleTime: 0,
  });

  return {
    ...fetchQuery,
    data: fetchQuery.data,
  };
};
