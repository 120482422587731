import { BiometriaEnum } from '@/types';
const BIOMETRIA_PREFIX = 'des_biometria:';

export const biometriaOptions = [
  {
    label: 'Aprovado',
    value: `${BIOMETRIA_PREFIX}${BiometriaEnum.APROVA}`,
  },
  {
    label: 'Reprovado',
    value: `${BIOMETRIA_PREFIX}${BiometriaEnum.REPROVA}`,
  },
  {
    label: 'Inconclusivo',
    value: `${BIOMETRIA_PREFIX}${BiometriaEnum.INCONCLUSIVO}`,
  },
  {
    label: 'Sem Biometria',
    value: `${BIOMETRIA_PREFIX}${BiometriaEnum.SEM_BIOMETRIA}`,
  },
];
