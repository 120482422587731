import { FloraProvider } from '@grupoboticario/flora-react';
import { gbInstitucionalTheme } from '@grupoboticario/flora';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './utils';
import { Mailing, IrDistributionFilter, AssistantTeamMembersList } from './pages';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorBoundary, PageWrapper } from './shared';

export default function Root() {
  return (
    <FloraProvider theme={gbInstitucionalTheme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename='/inicios/mailing'>
          <Routes>
            <Route path='/atendente' element={<PageWrapper title='Segmentação de base - IRs' />}>
              <Route
                path='base-de-irs'
                element={
                  <ErrorBoundary
                    key='segmentacao-base'
                    componentName='segmentacao-base'
                    fallbackMessage='Não foi possível carregar os dados da tela de segmentação de base'
                  >
                    <Mailing />
                  </ErrorBoundary>
                }
              />
            </Route>
            <Route path='/control' element={<PageWrapper title='Distribuição de base - IRs' />}>
              <Route
                path='distribuicao-irs'
                element={
                  <ErrorBoundary
                    key='distribuicao-irs'
                    componentName='distribuicao-base'
                    fallbackMessage='Não foi possível carregar os dados da tela de distribuição de base'
                  >
                    <IrDistributionFilter />
                  </ErrorBoundary>
                }
              />
              <Route
                path='selecionar-assistentes'
                element={
                  <ErrorBoundary
                    key='selecao-assistentes'
                    componentName='selecao-assistentes'
                    fallbackMessage='Não foi possível carregar os dados da tela de seleção de assistentes'
                  >
                    <AssistantTeamMembersList />
                  </ErrorBoundary>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </FloraProvider>
  );
}
