export enum PrefixNames {
  baseMediaSource = 'Origem de mídia da Base',
  cpCode = 'Código pai',
  des_biometria = 'Coleta Biométrica',
  endDate = 'Data final de cadastro',
  endDateDistributionData = 'Data final de distribuição',
  federativeUnit = 'UF',
  haveCredit = 'Crédito',
  haveIrAssigned = 'IRs não distribuídas',
  label = 'Campanha',
  operation = 'Operação',
  recadastro = 'Recadastro',
  reRegistrationEndDate = 'Data fim Recadastro',
  reRegistrationStartDate = 'Data início Recadastro',
  redistributionIr = 'Redistribuição de IRs',
  riskAnalysis = 'Risco',
  startDate = 'Data inicial de cadastro',
  startDateDistributionData = 'Data inicial de distribuição',
  tabulation = 'Tabulação',
}
