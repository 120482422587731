interface Config {
  api: string;
}

const isMock = process.env.MOCK === 'true';
const apiExample = 'https://api.example.com/';

export const config: Config = isMock
  ? {
      api: apiExample,
    }
  : {
      api: process.env.BFF_BASE_URL,
    };

export const configFrontCloud: Config = isMock
  ? {
      api: apiExample,
    }
  : {
      api: process.env.CLOUDFRONT_BASE_URL,
    };
