type NewRelicNoticeError = (error: Error, customProps?: object) => void;

interface LoadedWindow extends Window {
  NREUM?: {
    noticeError: NewRelicNoticeError;
  };
}

export const noticeError: NewRelicNoticeError = (error, customProps) => {
  const win: LoadedWindow = window;
  win.NREUM?.noticeError(error, customProps);
};
