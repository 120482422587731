import { KeyNames, PrefixNames } from '../../enums';
import { BiometriaEnum, FilterOptions, OperationEnum, RiskAnalysisControl } from '@/types';
import { toLocaleDate } from '@grupoboticario/vdi-mfe-utils';

const translationMap = new Map<string, string>(
  Object.entries(KeyNames).map(([key, value]) => [
    value,
    PrefixNames[key as keyof typeof PrefixNames],
  ])
);

const valueTranslationMap = new Map<string, string>([
  [BiometriaEnum.APROVA, 'Aprovado'],
  [BiometriaEnum.REPROVA, 'Reprovado'],
  [BiometriaEnum.SEM_BIOMETRIA, 'Sem Biometria'],
  [RiskAnalysisControl.HIGH_RISK, 'Alto'],
  [RiskAnalysisControl.LOW_RISK, 'Baixo'],
  [RiskAnalysisControl.MEDIUM_RISK, 'Médio'],
  [RiskAnalysisControl.NOT_PROCESSED, 'Sem risco'],
  [OperationEnum.BOTI, 'Boticário'],
  ['portais - organico', 'orgânico - portais'],
  ['outros - organico', 'orgânico - outros'],
  ['true', 'Sim'],
  ['false', 'Não'],
]);

const convertToFilterTag = (filterList: FilterOptions): string[] =>
  !!filterList &&
  Object.entries(filterList).flatMap(([key, value]) =>
    Array.isArray(value) ? value.map((v) => `${key}:${v}`) : `${key}:${value}`
  );

export const formatControlFilterTag = (filterList: FilterOptions) => {
  if (!filterList) {
    return undefined;
  }

  const convertedFilterList = convertToFilterTag(filterList);

  return convertedFilterList?.map((filter) => {
    const [key, value] = filter.split(':');
    const translatedKey = translationMap.get(key);
    const translatedValue = valueTranslationMap.get(value) ?? value;

    if (key.includes('Date')) {
      const dateFormatted = toLocaleDate(value);
      return {
        name: `${translatedKey}: ${dateFormatted}`,
        key: filter,
      };
    }

    return {
      name: `${translatedKey}: ${translatedValue}`,
      key: filter,
    };
  });
};
