import { fetcherCloudFrontGet } from '@/api/fetcher';

export interface getBaseMediaSourceOptionsResponse {
  label: string;
  value: string;
}

export const getBaseMediaSourceOptions = async (): Promise<getBaseMediaSourceOptionsResponse[]> => {
  const { data } = await fetcherCloudFrontGet('/baseMediaSource.json');
  return data;
};
