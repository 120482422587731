import { Dropdown, DropdownItem, DropdownProps } from '@grupoboticario/flora-react';

export type SelectDropdownItemType = { value: string; label: string };

interface SelectDropdownProps extends DropdownProps {
  selectDropdownItem: SelectDropdownItemType[];
}

export const SelectDropdown = ({ selectDropdownItem, ...rest }: SelectDropdownProps) => (
  <Dropdown
    data-testid='select-dropdown'
    {...rest}
    css={{
      '& .filter-dropdown-item > div': { pointerEvents: 'none' },
    }}
  >
    {selectDropdownItem.map((option) => {
      return (
        <DropdownItem
          className='filter-dropdown-item'
          key={`${rest.id}-${option.label}`}
          value={option.value}
          data-testid='select-dropdown-item'
        >
          {option.label}
        </DropdownItem>
      );
    })}
  </Dropdown>
);
