export enum KeyNames {
  baseMediaSource = 'baseMediaSource',
  cpCode = 'cpCode',
  des_biometria = 'des_biometria',
  endDate = 'endDate',
  endDateDistributionData = 'endDateDistributionData',
  federativeUnit = 'federativeUnit',
  haveCredit = 'haveCredit',
  haveIrAssigned = 'haveIrAssigned',
  label = 'label',
  operation = 'operation',
  recadastro = 'recadastro',
  redistributionIr = 'assistants',
  reRegistrationEndDate = 'reRegistrationEndDate',
  reRegistrationStartDate = 'reRegistrationStartDate',
  riskAnalysis = 'riskAnalysis',
  startDate = 'startDate',
  startDateDistributionData = 'startDateDistributionData',
  tabulation = 'tabulation',
}
