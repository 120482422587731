export enum MailingQueryKey {
  TOTAL_OPEN_SERVICES = 'totalOpenServices',
  MAILING_LIST_IR = 'mailingListIr',
}

export enum ControlQueryKey {
  ASSISTANT_TEAM_MEMBERS = 'assistantTeamMembers',
  ASSISTANT_TEAMS = 'assistantTeams',
  BASE_IR = 'baseIr',
  BASE_MEDIA_SOURCE_OPTIONS = 'BaseMediaSource',
  METADATA = 'metadata',
}
