import { config, configFrontCloud } from '@/config';
// eslint-disable-next-line import/no-unresolved
import { authService } from '@vdi/auth-service';
import axios from 'axios';

const instance = axios.create({
  baseURL: config.api,
});

const instanceCloudFront = axios.create({
  baseURL: configFrontCloud.api,
});

instanceCloudFront.interceptors.request.use((config) => {
  config.headers.set('Access-Control-Allow-Origin', '*');
  return config;
});

instance.interceptors.request.use(async (config) => {
  const { token } = await authService.getTokenOrRedirect();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export { instance, instanceCloudFront };
