import { Component, ReactNode } from 'react';
import { noticeError } from '../utils';
import { EmptyState } from '../empty-state';
import { CrossCircleIcon } from '@grupoboticario/flora-react-icons';

interface ErrorBoundaryProps {
  children: ReactNode;
  componentName?: string;
  fallbackMessage?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    noticeError(error, {
      mfeName: 'Mailing',
      sessionName: this.props.componentName,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <EmptyState
          title={this.props.fallbackMessage ?? `Não foi possível carregar os dados.`}
          subtitle='Por favor, tente novamente mais tarde!'
          icon={<CrossCircleIcon size={48} />}
        />
      );
    }

    return this.props.children;
  }
}
