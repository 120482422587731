import { FilterTagList } from '@/shared';
import { useControlMailingStore } from '@/store/control';
import { useShallow } from 'zustand/react/shallow';
import { Box } from '@grupoboticario/flora-react';
import { formatControlFilterTag } from './functions';

export const ControlFilerTag = () => {
  const { controlFilter } = useControlMailingStore(useShallow((state) => state));

  return (
    <Box>
      <FilterTagList filterTagResult={formatControlFilterTag(controlFilter)} variant='disabled' />
    </Box>
  );
};
