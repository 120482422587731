// eslint-disable-next-line import/no-unresolved
import { type IEventTracker } from '@vdi/event-tracker';
import { type Event, InteractionDetail, EventName } from '../types';
import { formatFilterTracker } from '../functions';
import { MailingData } from '@/types';
import { Table } from '@tanstack/react-table';

export const createAttendanceEvents = (
  tracker: IEventTracker<Event, unknown, unknown>,
  tableState: Table<MailingData>
) => {
  const lengthOfSelectedRowModel = () => tableState?.getSelectedRowModel().rows.length.toString();
  return {
    interactionFilterButton: (buttonName: string) => {
      tracker.track({
        event: 'analytics-event',
        event_parameters: {
          event_name: EventName.INTERACTION_MAILING,
          cd_interaction_detail: `${InteractionDetail.click}:${buttonName}`,
        },
      });
    },
    interactionDropdownSituation: (situationName: string, cod: string) => {
      tracker.track({
        event: 'analytics-event',
        event_parameters: {
          event_name: EventName.INTERACTION_MAILING,
          cd_interaction_detail: `${InteractionDetail.click}:situacao:${situationName}`,
          codigo: cod,
        },
      });
    },
    interactionFilters: (filters: string[]) => {
      tracker.track({
        event: 'analytics-event',
        event_parameters: {
          event_name: EventName.INTERACTION_MAILING_FILTERS,
          cd_interaction_detail: `${InteractionDetail.click}:aplicar-filtro`,
          cd_filtros: formatFilterTracker(filters),
        },
      });
    },
    interactionCheckAllCheckbox: () => {
      setTimeout(() => {
        tracker.track({
          event: 'analytics-event',
          event_parameters: {
            event_name: EventName.INTERACTION_MAILING,
            cd_interaction_detail: `${InteractionDetail.click}:${InteractionDetail.checkbox}:todasIRsSelecionadas`,
            cd_quantidade_checkbox: lengthOfSelectedRowModel(),
          },
        });
      }, 100);
    },
    interactionUnCheckAllCheckbox: () => {
      setTimeout(() => {
        tracker.track({
          event: 'analytics-event',
          event_parameters: {
            event_name: EventName.INTERACTION_MAILING,
            cd_interaction_detail: `${InteractionDetail.click}:${InteractionDetail.checkbox}:todasIRsDesSelecionadas`,
          },
        });
      }, 10);
    },
    interactionCheckbox: (cod: string) => {
      setTimeout(() => {
        tracker.track({
          event: 'analytics-event',
          event_parameters: {
            event_name: EventName.INTERACTION_MAILING,
            cd_interaction_detail: `${InteractionDetail.click}:${InteractionDetail.checkbox}:${cod}`,
            cd_quantidade_checkbox: lengthOfSelectedRowModel(),
          },
        });
      }, 10);
    },
    interactionCheckBoxMenu: (interactionDetail: string) => {
      tracker.track({
        event: 'analytics-event',
        event_parameters: {
          event_name: EventName.INTERACTION_MAILING,
          cd_interaction_detail: interactionDetail,
          cd_quantidade_checkbox: lengthOfSelectedRowModel(),
        },
      });
    },
    interactionClickPagination: () => {
      tracker.track({
        event: 'analytics-event',
        event_parameters: {
          event_name: EventName.INTERACTION_MAILING,
          cd_interaction_detail: `${InteractionDetail.click}:paginacao`,
        },
      });
    },
  };
};
